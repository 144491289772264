import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as items6fCXNSJTOvMeta } from "/app/pages/admin/items.vue?macro=true";
import { default as usersgSsTxNnO5UMeta } from "/app/pages/admin/users.vue?macro=true";
import { default as counterpartywx1PFSU0R9Meta } from "/app/pages/auth/counterparty.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as _91id_93csOQF5nkD7Meta } from "/app/pages/category/[id].vue?macro=true";
import { default as counterpartyBgJvFGlpOWMeta } from "/app/pages/guard/counterparty.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as _91id_93QIRuLOTNJ6Meta } from "/app/pages/product/[id].vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-items",
    path: "/admin/items",
    meta: items6fCXNSJTOvMeta || {},
    component: () => import("/app/pages/admin/items.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersgSsTxNnO5UMeta || {},
    component: () => import("/app/pages/admin/users.vue")
  },
  {
    name: "auth-counterparty",
    path: "/auth/counterparty",
    meta: counterpartywx1PFSU0R9Meta || {},
    component: () => import("/app/pages/auth/counterparty.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/app/pages/cart/index.vue")
  },
  {
    name: "category-id",
    path: "/category/:id()",
    meta: _91id_93csOQF5nkD7Meta || {},
    component: () => import("/app/pages/category/[id].vue")
  },
  {
    name: "guard-counterparty",
    path: "/guard/counterparty",
    meta: counterpartyBgJvFGlpOWMeta || {},
    component: () => import("/app/pages/guard/counterparty.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "product-id",
    path: "/product/:id()",
    meta: _91id_93QIRuLOTNJ6Meta || {},
    component: () => import("/app/pages/product/[id].vue")
  }
]